/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import DefaultLayout from './../layouts/Default'
import SEO from './../components/Seo'
import { graphql, Link } from 'gatsby'
import config from './../data/SiteConfig'
import LearnK8s from './../components/Testimonials/LearnK8s'
import HirolaGroup from './../components/Testimonials/HirolaGroup'
import GreenstreetBermam from './../components/Testimonials/GreenstreetBerman'
import Icon from './../components/Icon'
import Stack from './../components/Stack'

/**
 * Service page template
 */
class ServiceTemplate extends Component {
  /**
   * Resolve to the Testimonial component.
   *
   * This is limited to the options given here.
   *
   * @param name
   * @return {*}
   */
  getTestimonial = name => {
    let testimonial
    switch (name) {
    case 'LearnK8s':
      testimonial = LearnK8s
      break
    case 'HirolaGroup':
      testimonial = HirolaGroup
      break
    case 'GreenstreetBerman':
      testimonial = GreenstreetBermam
      break
    }
    return (
      React.createElement(testimonial)
    )
  }
  render () {
    const service = this.props.data.markdownRemark
    return (
      <DefaultLayout className={`page-service markdown-content-page service-${ service.frontmatter.service_name }`}>
        <SEO
          pageType={`webpage`}
          pageTitle={service.frontmatter.title}
          fullPageTitle={service.frontmatter.title}
          pageDescription={service.frontmatter.description}
          pageImage={config.siteImage}
          pagePath={service.frontmatter.path}
        />
        <div className="container">
          <div className="row pt-10 pt-lg-12">
            <article className="col-lg-9 col-12">
              <div className="row justify-content-start heading pl-lg-0 pl-3">
                <Icon type={service.frontmatter.icon}/>
                <h1 className={`pl-lg-5 pl-1 pt-6 pt-lg-7`}>{service.frontmatter.title}</h1>
              </div>
              <div className="content pt-lg-8">
                <div dangerouslySetInnerHTML={{ __html: service.html }}>
                </div>
              </div>
              <div className="row pt-5 pt-lg-8">
                <div className="col-12 col-md-auto col-sm-5 text-lg-left text-center">
                  <Link to="/services" className={`btn btn-outline-secondary btn-lg`}>
                    Other services
                  </Link>
                </div>
                <div className="col-12 col-md-auto col-sm-5 text-lg-left text-center pt-sm-0 pt-3">
                  <Link to="/contact" className={`btn btn-warning btn-lg`}>
                    Get in touch
                  </Link>
                </div>
              </div>
            </article>
            <aside className="col-lg-3 col-12 pt-lg-5 pt-5">
              <div className="sidebar-content ml-lg-5 sticky-top sticky-offset-header">
                {this.getTestimonial(service.frontmatter.testimonial)}
              </div>
            </aside>
          </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default ServiceTemplate

export const serviceQuery = graphql`
  query ServicePageByPath($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path} }) {
      html
      frontmatter {
        path
        title
        testimonial
        icon
        description
        service_name
      }
    }
  }
`
